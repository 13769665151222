// Our url scheme is `//<ENV-><SUBDOMAIN>.buildable.<TLD>`
// Using this we can inspect the `host` value of the window,
// getting the correct env w/o relying on env vars

export default function getEnvFromHost() {
  try {
    if (window?.location) {
      if (window.location.hostname === 'localhost') {
        return 'localhost';
      }

      const urlPieces = window.location.host.split('.');
      // For feature builds, allow vercel domain
      if (urlPieces[urlPieces.length - 2] === 'vercel') {
        return 'development';
      }

      // grab the subdomain off the url
      const subdomain = urlPieces[0];
      // try splitting the subdomain on `-` to get env
      const sdParts = subdomain.split('-');
      // if there's only one element, we're in prod
      if (sdParts.length === 1) {
        return 'production';
      }

      const [inferredEnv] = sdParts;
      return inferredEnv;
    }
  } catch (err) {
    // This error happens if the server attempts to access `window`
  }
  return null;
}
