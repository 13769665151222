// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getCookie, parseJwt } from '../../helpers/utility';
import getEnvFromHost from '../utils/getEnvFromHost';

const env = getEnvFromHost();

// todo: move somewhere 
const bldEnv = env === 'production' ? 'live' : 'test';
const subDomain = env === 'production' ? '' : `${env === 'localhost' ? 'development' : env}-`;
const tld = env === 'production' ? 'dev' : 'io';
const url = `https://${subDomain}api.buildable.${tld}/trigger/v2/${bldEnv}-98b55d1d-dd12-45b5-ac49-0b22e3c88e2f`;

// TODO: simplify/isolate
const hostList = {
  staging: 'buildable.io',
  production: 'buildable.dev',
};

export default function useAuth() {
  // const [token, setToken] = useState(); // TODO: lean more on token for auth
  const [user, setUser] = useState();
  const [host, setHost] = useState();

  async function login ({ email, password }) {
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
        'content-type': 'application/json;charset=UTF-8'
      }
    });
    const userInfo = await res.json();
    setAuth(userInfo);
    return userInfo;
  }

  useEffect(() => {
    try {
      const h = hostList[env] ?? globalThis?.location?.hostname;
      setHost(h);
    } catch {
        // todo: find a better way to handle potentioal SSR window refs 🧐
    }
  }, []);

  function setAuth(data) {
    try {
      const { exp } = parseJwt(data?.token);
      // expire the cookie _when the token expires_
      const expires = new Date(exp * 1000);

      const cookie = {
        authKey: data?.token,
        email: data?.email,
        _id: data?._id ?? '',
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
        timeZone: data?.timeZone ?? '',
        role: data?.role,
      };

      Cookies.set('buildable', cookie, {
        expires,
        domain: host,
        secure: true,
        path: '/'
      });

      setUser({ ...cookie });
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    // TODO: listen for updates to the auth_token in LS
    // for cross tab auth things

    // initialize
    const cookie = getCookie();
    if (!user?.authKey && cookie?.authKey) {
      setUser({ ...cookie });
    }
  }, [user]);

  return {
    // token,
    user,
    login,
  }
}
