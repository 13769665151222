var hookTypes = ['after', 'before', 'onError']; // todo: util

var getQueryParams = function getQueryParams() {
  var params = [];

  try {
    var _globalThis$location$, _globalThis$location, _globalThis$location$2;

    params = (_globalThis$location$ = (_globalThis$location = globalThis.location) === null || _globalThis$location === void 0 ? void 0 : (_globalThis$location$2 = _globalThis$location.search.replace('?', '')) === null || _globalThis$location$2 === void 0 ? void 0 : _globalThis$location$2.split('&')) !== null && _globalThis$location$ !== void 0 ? _globalThis$location$ : [];
  } catch (_unused) {// now window in SSR
  }

  return params;
};

export var isValidHookType = function isValidHookType(type) {
  return new RegExp(hookTypes.join('|')).test(type);
}; // TEMP PATCH
// b/c the current implementation is entwined with a lot of implementation
// just check the query params here for now and override the value if needed

var paramKeys = getQueryParams().map(function (qp) {
  return qp === null || qp === void 0 ? void 0 : qp.split('=')[0];
});
var showHookNodes = !!process.env.NEXT_PUBLIC_SHOW_HOOK_NODES || paramKeys.includes('enable-hooks');
export var SHOW_HOOK_NODE = showHookNodes;
export function fromSpecificToShallowType(value) {
  return value;
}
export function fromShallowToSpecificType(value) {
  return value;
}
export function makeEditorId(id, type) {
  return "".concat(type, "-").concat(id);
}
export function incrementString(value, amount) {
  var newValue = parseInt(fromSpecificToShallowType(value), 10);
  return fromShallowToSpecificType("".concat(newValue + amount));
}
export var OS;

(function (OS) {
  OS["Windows"] = "Windows";
  OS["Mac"] = "Mac";
  OS["Linux"] = "Linux";
  OS["Android"] = "Android";
  OS["iOS"] = "iOS";
  OS["Other"] = "Other";
})(OS || (OS = {}));

export function getOS() {
  var userAgent = window.navigator.userAgent.toLowerCase();
  var macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/;
  var windowsPlatforms = /(win32|win64|windows|wince)/;
  var iosPlatforms = /(iphone|ipad|ipod)/;

  if (macosPlatforms.test(userAgent)) {
    return OS.Mac;
  }

  if (iosPlatforms.test(userAgent)) {
    return OS.iOS;
  }

  if (windowsPlatforms.test(userAgent)) {
    return OS.Windows;
  }

  if (/android/.test(userAgent)) {
    return OS.Android;
  }

  if (/linux/.test(userAgent)) {
    return OS.Linux;
  }

  return OS.Other;
}